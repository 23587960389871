@use '@geneplanet/bootstrap/scss/shared/colors.scss';
@import 'sass-google-fonts/src/GoogleFonts.scss';
@include google-font('Open Sans', '300,400,500,600,700,800,900');

@import 'swiper/swiper-bundle.min.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@geneplanet/bootstrap/scss/bootstrap.scss';
@import '@geneplanet/bootstrap/scss/sprite.scss';
@import '@geneplanet/ngx-ui/scss/styles';

@import 'modals';
@import 'variables.scss';
@import 'theme-wsb.scss';
@import 'theme-ps.scss';
@import '../app/shared/shared.style.scss';

$table-breakpoint: 991px;

.payment-options-ribbon-container a {
  color: colors.$white;
  font-weight: bold;
  text-decoration: underline;
}

body.styles-loaded {
  transition-property: opacity;
  transition-delay: 1000ms;
  transition-duration: 200ms;
  opacity: 1 !important;
}

.bg-teal {
  background: linear-gradient(105.01deg, #17dddb 0%, #00b9bb 100%);
}

.wrapper {
  max-width: 47rem;
  width: 100%;
  margin: 0 auto;
}

:root {
  --scrollbar-width: 0px;
}

main.main-content {
  margin-top: $header-height;
  min-height: calc(100vh - #{$header-height});
}

.grecaptcha-badge {
  visibility: hidden;
}

.circle .img-wrapper img {
  border-radius: 50%;
}

.modal {
  .modal-content {
    border: 0;
  }

  .close {
    outline: none;
  }
}

.wave-background-container {
  margin-top: -20.75rem;
  height: 20.75rem;
}

.narrow-container {
  max-width: 53.25rem;
  margin: 0 auto;
}

.wide-container {
  max-width: 87.5rem;
  margin: 0 auto;
}

.gap-48 {
  gap: 48px;
}

.gap-24 {
  gap: 24px;
}

.wave-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-size: 100% 100%;
  background-image: url('/assets/images_local/green_wave.svg');

  &.wave-2 {
    background-image: url('/assets/images_local/green_wave_2.svg');
  }

  &.wave-3 {
    background-image: url('/assets/images_local/green_wave_3.svg');
  }
}

.text-muted {
  color: colors.$text-muted !important;
}

.text-light-info {
  color: $light-info !important;
}

.bg-light-info {
  background: $light-info;
}

.bg-light-info-2 {
  background: $bg-lightblue-2;
}

.bg-transparent-info {
  background: rgba(217, 245, 245, 0.2);
}

.bg-light-gray {
  background: $gray-2;
}

.bg-lightest-gray {
  background: $gray-1;
}

.bg-premium {
  background: #f4f7ff;
}

.border-radius {
  border-radius: $border-radius;
}
.border-radius-24 {
  border-radius: $border-radius * 2;
}

.border-radius-8 {
  border-radius: calc($border-radius / 2);
}

.img-shadow {
  box-shadow: 0px 40px 48px -24px rgba(78, 95, 124, 0.45);
}

.border-top-left-radius {
  border-top-left-radius: $border-radius;
}

.border-top-right-radius {
  border-top-right-radius: $border-radius;
}

.border-bottom-left-radius {
  border-bottom-left-radius: $border-radius;
}

.border-bottom-right-radius {
  border-bottom-right-radius: $border-radius;
}

.w-lt-md-100 {
  width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  position: relative;
  width: calc(100vw - var(--scrollbar-width));
  margin-left: calc(-50vw + 50%);
}

.opacity-5 {
  opacity: 0.5;
}

.country-flag {
  width: 32px;
  border: 1px solid #efeff4;
}

.card {
  border: 0 !important;
}

.accordion > .card {
  > .card-header {
    margin-bottom: 0 !important;
  }

  .card-body {
    border-bottom: 1px solid $border-color;
  }
}

.wave-background-container {
  pointer-events: none;
}

.popover {
  max-width: unset;
  width: 20rem;
  border: 0;
  border-radius: $border-radius;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
  z-index: 1029;

  .arrow::before {
    border: unset;
  }

  .popover-body {
    padding: 0;
  }
}

.radio-container {
  align-items: center;
  margin-bottom: 0 !important;
}

.hide-mobile {
  visibility: hidden;
}

textarea#message {
  height: 7rem !important;
}

.highcharts-root {
  font-family: $font-family-base !important;
}

.b4-semibold {
  @include font-size(12px !important);
  line-height: 19px !important;
  font-weight: 600 !important;
}

.gbtn-1-pink {
  background: linear-gradient(271.16deg, #eb5b8d 0.99%, #ff9abd 100%);
  color: #fff;
  padding: 0.75rem 2rem;
  border: 0;
  border-radius: 100px;

  &:hover {
    color: #fff;
  }
}

@include media-breakpoint-up(md) {
  .gpicon-md-l {
    height: 2.5rem !important;
    width: 2.5rem !important;
    font-size: 2.5rem !important;
  }

  .w-lt-md-100 {
    width: 352px !important;
  }

  .popover {
    width: 28rem;
  }

  .hide-mobile {
    visibility: visible;
  }

  textarea#message {
    height: 9rem !important;
  }
}

@include media-breakpoint-down(lg) {
  .w-50.has-shadow {
    width: calc(50% + 3rem) !important;
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }
}

.h-flip {
  transform: scale(-1, 1);
}

.narrow {
  max-width: 952px;
  margin-left: auto;
  margin-right: auto;
}

// Hide re-captcha error "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge."
// This will hide body > div > div, which should not effect other elements (other have class)
body > div:not([class]) > div:not([class]) {
  display: none;
}

.cdk-overlay-container {
  z-index: 1051;
}

/*------------------------------------
  Table typography overrides
------------------------------------*/

.th1-bold {
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

.tv1-extrabold {
  font-size: 26px !important;
  font-weight: 800 !important;
  line-height: 40px !important;
}

.tv2-extrabold {
  font-size: 22px !important;
  font-weight: 800 !important;
  line-height: 32px !important;
}

.tv2-bold {
  font-size: 15px !important;
  font-weight: 800 !important;
  line-height: 24px !important;
}

.tv4-bold {
  font-size: 17px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
}

.ts1-regular {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.tt1-semibold {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 16px !important;
}

.tt1-regular {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.td1-regular {
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.tl1-bold {
  font-size: 9px !important;
  font-weight: 700 !important;
  line-height: 16px !important;
}

/* Table S */
@media screen and (max-width: $table-breakpoint) {
  .th1-bold {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 24px !important;
  }

  .tv1-extrabold {
    font-size: 20px !important;
    font-weight: 800 !important;
    line-height: 28px !important;
  }

  .tv2-bold {
    font-size: 9px !important;
    font-weight: 800 !important;
    line-height: 16px !important;
  }

  .ts1-regular {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
  }

  .tt1-semibold {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 16px !important;
  }

  .tt1-regular {
    font-size: 10px !important;
    font-weight: 400 !important;
    line-height: 16px !important;
  }

  .td1-regular {
    font-size: 8px !important;
    font-weight: 400 !important;
    line-height: 12px !important;
  }
}

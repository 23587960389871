@use '@geneplanet/bootstrap/scss/shared/breakpoints';

app-logo {
  @include breakpoints.media-breakpoint-up(xxl) {
    .d-xxl-none {
      display: none !important;
    }
    .d-xxl-block {
      display: block !important;
    }
  }
}

app-cdn-image {
  .read-more-img {
    max-width: 18rem;
    max-height: 15.75rem;
  }

  .badge-img {
    width: 96px;
  }

  .mama-club-logo {
    max-height: 90px;
    object-fit: contain;
  }

  .bottom-right {
    bottom: 0;
    right: 0;
  }

  .consultation-img {
    width: 48px;
    height: 48px;
  }

  .influencer-image {
    width: 8rem;
    border-radius: 0.75rem;

    @include breakpoints.media-breakpoint-up(xl) {
      width: 15.75rem;
    }
  }

  .stripe-img {
    @include breakpoints.media-breakpoint-down(lg) {
      max-width: 7rem;
    }
  }
}

app-data-graph-row .progress {
  height: 0.5rem;
  background: $gray-1;

  .progress-bar {
    background: $gray-4;
  }
}

app-intro-testimonials-section {
  .swiper-container {
    padding: 0 !important;
  }

  .testimonial-image {
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  }
}

app-back-button-bar {
  @include media-breakpoint-up(md) {
    .back-button-content {
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .back-button-container,
  .back-button {
    height: 4rem;
  }

  .back-button {
    position: fixed;
    z-index: 1;
  }
}

app-toast .toast-container {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1029;

  .toast-box {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
    border-radius: $border-radius;
    background: #fff;

    .dismissible-icon {
      width: auto;
    }
  }

  &.important {
    .message,
    .dismissible-icon {
      color: $danger;
    }
  }
}

app-fullscreen-overlay .overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1031;
}

@include media-breakpoint-down(lg) {
  app-logo .logo-img {
    height: 1.5rem;
  }

  app-back-button-bar {
    .back-button-container,
    .back-button {
      height: 3.5rem;
    }
  }
}

.contact-link {
  width: 80px;
  height: 80px;

  @include media-breakpoint-down(lg) {
    width: 64px;
    height: 64px;
  }
}

%pulse-ring {
  width: 60px;
  height: 60px;
  top: -20px;
  left: 10px;
  transform: scale(0.6);
  z-index: -1;
  border-radius: 50%;
  position: absolute;

  @include media-breakpoint-down(lg) {
    width: 44px;
    height: 44px;
    top: -10px;
  }
}

@mixin pulsateWithColor($color, $name) {
  animation: pulsate#{$name} infinite 3s;
  @keyframes pulsate#{$name} {
    0% {
      box-shadow: 0 0 13px $color;
    }
    50% {
      box-shadow: 0 0 80px $color;
    }
    100% {
      box-shadow: 0 0 13px $color;
    }
  }
}

.pulse-ring {
  &-whatsapp {
    @extend %pulse-ring;
    @include pulsateWithColor(#2bb741dd, 'whatsapp');
  }
  &-viber {
    @extend %pulse-ring;
    @include pulsateWithColor(#7360f2dd, 'viber');
  }
  &-facebook {
    @extend %pulse-ring;
    @include pulsateWithColor(#778cf8dd, 'facebook');
  }
  &-contact {
    @extend %pulse-ring;
    @include pulsateWithColor(#778cf8dd, 'contact');
  }
}

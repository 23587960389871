@use '@geneplanet/bootstrap/scss/shared/colors.scss';
@use '@geneplanet/bootstrap/scss/shared/shadows';
@use 'variables.scss';

$animationSpeed: 300ms;

.modal-backdrop.show {
  opacity: 0.25;
}

/*------------------------------------
  BASIC MODAL
------------------------------------*/

.basic-modal .modal-dialog {
  top: 40%;

  .gpicon-close {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

/*------------------------------------
  FULLSCREEN MODAL
------------------------------------*/

body.modal-open.fullscreen-modal-open {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
}

.fullscreen-modal {
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .modal-dialog {
    position: fixed;
    margin: 0;
    min-width: 100%;
    height: 100%;
    padding: 0;
  }

  .modal-content {
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border: 0;
    background: #fff;

    & > * {
      overflow: initial;
      height: 100%;
    }
  }
}

/*------------------------------------
  INFO MODAL
------------------------------------*/

.modal-backdrop,
.info-modal-backdrop {
  z-index: 1039 !important;
}
.info-modal-backdrop {
  @include media-breakpoint-down(md) {
    animation: backdropFadein $animationSpeed;
  }
}

.info-modal {
  z-index: 1040;
  &.modal.modal-static .modal-dialog {
    transform: none;
  }

  &.modal.fade .modal-dialog {
    transform: none;
    transition: none;
  }

  .custom-scroll-parent {
    height: 100%;
  }

  .modal-dialog {
    max-width: 43.5rem;
  }

  .modal-content {
    position: fixed;
    bottom: 0;
    border: 0;
    max-height: calc(100vh - 1rem);
    border-radius: 0.75rem;
    overflow: hidden;

    .modal-body {
      border-radius: 0.75rem;
      ul li {
        margin-bottom: 1rem;
      }
    }
  }

  .modal-header {
    height: 0;
    padding: 0;
    z-index: 2;
    align-items: start;

    .btn-close {
      background: transparent;
      width: unset;
      height: unset;
      padding: 0.25rem;
      margin-top: 0.5rem;
      margin-right: 0.5rem;
      z-index: 10;
      box-shadow: none;
    }
  }

  .modal-body {
    overflow: auto;
    max-height: 45rem;
    padding: 3rem 1rem 1rem 1rem;

    @media (max-height: 60rem) {
      max-height: 75vh;
    }
  }

  @include media-breakpoint-down(sm) {
    &.full {
      .modal-content {
        height: calc(100% - 1rem);
      }
    }
    .modal-content {
      height: auto;
      max-height: calc(100vh - 1rem);
      transform: translateY(100%);
      transition: transform ease-in-out $animationSpeed;
    }
    &.fade {
      opacity: 1 !important;
    }
    &.show {
      .modal-content {
        transform: translateY(0);
      }
    }

    .modal-body {
      height: 100%;
      max-height: calc(100vh - 1rem);
      &.custom-scroll > .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .modal-dialog {
      position: fixed !important;
      top: 0 !important;
      right: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin: 0 !important;
      overflow-y: initial !important;
      min-width: 100%;
      max-width: 100%;
    }

    .modal-content {
      border-radius: 0.75rem 0.75rem 0 0;
      border: 0.5rem solid transparent;
      background-color: transparent;
      & > * {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: colors.$white;
        align-items: stretch;

        .modal-header,
        .modal-footer {
          flex-grow: 0;
        }
      }

      .modal-body {
        border-radius: 0.75rem 0.75rem 0 0;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    .modal-body {
      padding: 3.5rem 4rem;
    }
  }

  @include media-breakpoint-up(md) {
    .modal-dialog {
      display: flex;
      align-items: center;
      margin: 0 auto;
      height: 100vh;
    }

    .modal-content {
      position: relative;
      animation: none;

      &:before,
      &:after {
        height: 3.5rem;
      }
    }
  }

  &.no-upper-fade .modal-content:before {
    height: 0;
  }
}

@keyframes backdropFadein {
  from {
    background-color: transparent;
  }
  to {
    background-color: black;
  }
}

$prime-ps: #009ee0;
$second-ps: #00bdf2;
$light-ps: #ccf2fc;
$ultra-light-ps: rgba(204, 242, 252, 0.2);
$gradient-ps: linear-gradient(180deg, #00bdf2 0%, #009ee0 100%);
$light-info-wsb: rgba(255, 57, 127, 0.75);
$lighter-info-wsb: #ff9bbe;
$transparent-info-wsb: rgba(255, 230, 238, 0.2);

$info-ps: #ff6161;
$lighter-info-ps: #341ec0;
$transparent-info-ps: rgba(255, 230, 238, 0.2);

.theme-ps {
  .gbtn-1,
  .gbtn-2 {
    background-color: $prime-ps;
    color: #fff !important;
  }

  .text-primary,
  .call-number-content a {
    color: $prime-ps !important;
  }

  .text-light-info {
    color: $prime-ps !important;
  }

  .text-info {
    color: $prime-ps !important;
  }

  .bg-info {
    background: $info-ps !important;
  }
  .bg-light-info {
    background: $prime-ps;
  }

  .bg-transparent-info {
    background: $ultra-light-ps !important;
  }

  app-data-table-container {
    .bg-light-info {
      background: $lighter-info-ps;
    }
    .text-light-info {
      color: $prime-ps !important;
    }
  }
  app-numbered-list-row {
    .step {
      background-color: $prime-ps;
    }
  }
  app-data-graph-row {
    .progress {
      .progress-bar.bg-info {
        background-color: $prime-ps !important;
      }
    }
  }

  .bg-lightblue {
    background-color: $ultra-light-ps !important;
  }

  .cell-content .gpicon-check {
    color: $prime-ps !important;
  }

  .uvp-background {
    background: $gradient-ps;
  }

  .badge-wrapper {
    width: 252px !important;
  }

  .wave-background {
    background-image: url('/assets/images_local/prenascan/wave.svg');
    &.h-flip {
      transform: none;
    }

    &.wave-2 {
      background-image: url('/assets/images_local/wsb/wave_2.svg');
    }

    &.wave-3 {
      background-image: url('/assets/images_local/prenascan/wave_3.svg');
    }
  }
}

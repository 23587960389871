$info-wsb: #ff6199;
$light-info-wsb: rgba(255, 57, 127, 0.75);
$lighter-info-wsb: #ff9bbe;
$transparent-info-wsb: rgba(255, 230, 238, 0.2);

.theme-wsb {
  .text-light-info {
    color: $light-info-wsb !important;
  }

  .text-info {
    color: $info-wsb !important;
  }

  .bg-info {
    background: $info-wsb !important;
  }
  .bg-light-info {
    background: $light-info-wsb;
  }

  .bg-transparent-info {
    background: $transparent-info-wsb !important;
  }

  app-data-table-container {
    .bg-light-info {
      background: $lighter-info-wsb;
    }
    .text-light-info {
      color: $lighter-info-wsb !important;
    }
  }
  app-numbered-list-row {
    .step {
      background-color: $lighter-info-wsb;
    }
  }
  app-data-graph-row {
    .progress {
      .progress-bar.bg-info {
        background-color: $light-info-wsb !important;
      }
    }
  }

  app-packages-hero,
  app-call-number,
  app-contact-step {
    a,
    i {
      color: $light-info-wsb !important;
    }
  }

  .uvp-background {
    background: linear-gradient(180deg, #ff9bbe 0%, #ff6b9f 100%);
  }

  .badge-wrapper {
    width: 252px !important;
  }

  .wave-background {
    background-image: url('/assets/images_local/wsb/wave.svg');

    &.wave-2 {
      background-image: url('/assets/images_local/wsb/wave_2.svg');
    }

    &.wave-3 {
      background-image: url('/assets/images_local/wsb/wave_3.svg');
    }
  }
}
